// 26.1.6 Reflect.get(target, propertyKey [, receiver])
var gOPD = require('./_object-gopd'),
  getPrototypeOf = require('./_object-gpo'),
  has = require('./_has'),
  $export = require('./_export'),
  isObject = require('./_is-object'),
  anObject = require('./_an-object');
function get(target, propertyKey /*, receiver*/) {
  var receiver = arguments.length < 3 ? target : arguments[2],
    desc,
    proto;
  if (anObject(target) === receiver) return target[propertyKey];
  if (desc = gOPD.f(target, propertyKey)) return has(desc, 'value') ? desc.value : desc.get !== undefined ? desc.get.call(receiver) : undefined;
  if (isObject(proto = getPrototypeOf(target))) return get(proto, propertyKey, receiver);
}
$export($export.S, 'Reflect', {
  get: get
});