var global = require('./_global'),
  core = require('./_core'),
  hide = require('./_hide'),
  redefine = require('./_redefine'),
  ctx = require('./_ctx'),
  PROTOTYPE = 'prototype';
var $export = function (type, name, source) {
  var IS_FORCED = type & $export.F,
    IS_GLOBAL = type & $export.G,
    IS_STATIC = type & $export.S,
    IS_PROTO = type & $export.P,
    IS_BIND = type & $export.B,
    target = IS_GLOBAL ? global : IS_STATIC ? global[name] || (global[name] = {}) : (global[name] || {})[PROTOTYPE],
    exports = IS_GLOBAL ? core : core[name] || (core[name] = {}),
    expProto = exports[PROTOTYPE] || (exports[PROTOTYPE] = {}),
    key,
    own,
    out,
    exp;
  if (IS_GLOBAL) source = name;
  for (key in source) {
    // contains in native
    own = !IS_FORCED && target && target[key] !== undefined;
    // export native or passed
    out = (own ? target : source)[key];
    // bind timers to global for call from export context
    exp = IS_BIND && own ? ctx(out, global) : IS_PROTO && typeof out == 'function' ? ctx(Function.call, out) : out;
    // extend global
    if (target) redefine(target, key, out, type & $export.U);
    // export
    if (exports[key] != out) hide(exports, key, exp);
    if (IS_PROTO && expProto[key] != out) expProto[key] = out;
  }
};
global.core = core;
// type bitmap
$export.F = 1; // forced
$export.G = 2; // global
$export.S = 4; // static
$export.P = 8; // proto
$export.B = 16; // bind
$export.W = 32; // wrap
$export.U = 64; // safe
$export.R = 128; // real proto method for `library` 
module.exports = $export;