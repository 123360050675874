'use strict';

// 19.1.2.1 Object.assign(target, source, ...)
var getKeys = require('./_object-keys'),
  gOPS = require('./_object-gops'),
  pIE = require('./_object-pie'),
  toObject = require('./_to-object'),
  IObject = require('./_iobject'),
  $assign = Object.assign;

// should work with symbols and should have deterministic property order (V8 bug)
module.exports = !$assign || require('./_fails')(function () {
  var A = {},
    B = {},
    S = Symbol(),
    K = 'abcdefghijklmnopqrst';
  A[S] = 7;
  K.split('').forEach(function (k) {
    B[k] = k;
  });
  return $assign({}, A)[S] != 7 || Object.keys($assign({}, B)).join('') != K;
}) ? function assign(target, source) {
  // eslint-disable-line no-unused-vars
  var T = toObject(target),
    aLen = arguments.length,
    index = 1,
    getSymbols = gOPS.f,
    isEnum = pIE.f;
  while (aLen > index) {
    var S = IObject(arguments[index++]),
      keys = getSymbols ? getKeys(S).concat(getSymbols(S)) : getKeys(S),
      length = keys.length,
      j = 0,
      key;
    while (length > j) if (isEnum.call(S, key = keys[j++])) T[key] = S[key];
  }
  return T;
} : $assign;