var ctx = require('./_ctx'),
  call = require('./_iter-call'),
  isArrayIter = require('./_is-array-iter'),
  anObject = require('./_an-object'),
  toLength = require('./_to-length'),
  getIterFn = require('./core.get-iterator-method'),
  BREAK = {},
  RETURN = {};
var exports = module.exports = function (iterable, entries, fn, that, ITERATOR) {
  var iterFn = ITERATOR ? function () {
      return iterable;
    } : getIterFn(iterable),
    f = ctx(fn, that, entries ? 2 : 1),
    index = 0,
    length,
    step,
    iterator,
    result;
  if (typeof iterFn != 'function') throw TypeError(iterable + ' is not iterable!');
  // fast case for arrays with default iterator
  if (isArrayIter(iterFn)) for (length = toLength(iterable.length); length > index; index++) {
    result = entries ? f(anObject(step = iterable[index])[0], step[1]) : f(iterable[index]);
    if (result === BREAK || result === RETURN) return result;
  } else for (iterator = iterFn.call(iterable); !(step = iterator.next()).done;) {
    result = call(iterator, f, step.value, entries);
    if (result === BREAK || result === RETURN) return result;
  }
};
exports.BREAK = BREAK;
exports.RETURN = RETURN;