var metadata = require('./_metadata'),
  anObject = require('./_an-object'),
  getPrototypeOf = require('./_object-gpo'),
  ordinaryHasOwnMetadata = metadata.has,
  ordinaryGetOwnMetadata = metadata.get,
  toMetaKey = metadata.key;
var ordinaryGetMetadata = function (MetadataKey, O, P) {
  var hasOwn = ordinaryHasOwnMetadata(MetadataKey, O, P);
  if (hasOwn) return ordinaryGetOwnMetadata(MetadataKey, O, P);
  var parent = getPrototypeOf(O);
  return parent !== null ? ordinaryGetMetadata(MetadataKey, parent, P) : undefined;
};
metadata.exp({
  getMetadata: function getMetadata(metadataKey, target /*, targetKey */) {
    return ordinaryGetMetadata(metadataKey, anObject(target), arguments.length < 3 ? undefined : toMetaKey(arguments[2]));
  }
});