var metadata = require('./_metadata'),
  anObject = require('./_an-object'),
  toMetaKey = metadata.key,
  getOrCreateMetadataMap = metadata.map,
  store = metadata.store;
metadata.exp({
  deleteMetadata: function deleteMetadata(metadataKey, target /*, targetKey */) {
    var targetKey = arguments.length < 3 ? undefined : toMetaKey(arguments[2]),
      metadataMap = getOrCreateMetadataMap(anObject(target), targetKey, false);
    if (metadataMap === undefined || !metadataMap['delete'](metadataKey)) return false;
    if (metadataMap.size) return true;
    var targetMetadata = store.get(target);
    targetMetadata['delete'](targetKey);
    return !!targetMetadata.size || store['delete'](target);
  }
});