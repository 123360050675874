// 0 -> Array#forEach
// 1 -> Array#map
// 2 -> Array#filter
// 3 -> Array#some
// 4 -> Array#every
// 5 -> Array#find
// 6 -> Array#findIndex
var ctx = require('./_ctx'),
  IObject = require('./_iobject'),
  toObject = require('./_to-object'),
  toLength = require('./_to-length'),
  asc = require('./_array-species-create');
module.exports = function (TYPE, $create) {
  var IS_MAP = TYPE == 1,
    IS_FILTER = TYPE == 2,
    IS_SOME = TYPE == 3,
    IS_EVERY = TYPE == 4,
    IS_FIND_INDEX = TYPE == 6,
    NO_HOLES = TYPE == 5 || IS_FIND_INDEX,
    create = $create || asc;
  return function ($this, callbackfn, that) {
    var O = toObject($this),
      self = IObject(O),
      f = ctx(callbackfn, that, 3),
      length = toLength(self.length),
      index = 0,
      result = IS_MAP ? create($this, length) : IS_FILTER ? create($this, 0) : undefined,
      val,
      res;
    for (; length > index; index++) if (NO_HOLES || index in self) {
      val = self[index];
      res = f(val, index, O);
      if (TYPE) {
        if (IS_MAP) result[index] = res; // map
        else if (res) switch (TYPE) {
          case 3:
            return true;
          // some
          case 5:
            return val;
          // find
          case 6:
            return index;
          // findIndex
          case 2:
            result.push(val);
          // filter
        } else if (IS_EVERY) return false; // every
      }
    }
    return IS_FIND_INDEX ? -1 : IS_SOME || IS_EVERY ? IS_EVERY : result;
  };
};