var Set = require('./es6.set'),
  from = require('./_array-from-iterable'),
  metadata = require('./_metadata'),
  anObject = require('./_an-object'),
  getPrototypeOf = require('./_object-gpo'),
  ordinaryOwnMetadataKeys = metadata.keys,
  toMetaKey = metadata.key;
var ordinaryMetadataKeys = function (O, P) {
  var oKeys = ordinaryOwnMetadataKeys(O, P),
    parent = getPrototypeOf(O);
  if (parent === null) return oKeys;
  var pKeys = ordinaryMetadataKeys(parent, P);
  return pKeys.length ? oKeys.length ? from(new Set(oKeys.concat(pKeys))) : pKeys : oKeys;
};
metadata.exp({
  getMetadataKeys: function getMetadataKeys(target /*, targetKey */) {
    return ordinaryMetadataKeys(anObject(target), arguments.length < 2 ? undefined : toMetaKey(arguments[1]));
  }
});