var has = require('./_has'),
  toIObject = require('./_to-iobject'),
  arrayIndexOf = require('./_array-includes')(false),
  IE_PROTO = require('./_shared-key')('IE_PROTO');
module.exports = function (object, names) {
  var O = toIObject(object),
    i = 0,
    result = [],
    key;
  for (key in O) if (key != IE_PROTO) has(O, key) && result.push(key);
  // Don't enum bug & hidden keys
  while (names.length > i) if (has(O, key = names[i++])) {
    ~arrayIndexOf(result, key) || result.push(key);
  }
  return result;
};