var metadata = require('./_metadata'),
  anObject = require('./_an-object'),
  aFunction = require('./_a-function'),
  toMetaKey = metadata.key,
  ordinaryDefineOwnMetadata = metadata.set;
metadata.exp({
  metadata: function metadata(metadataKey, metadataValue) {
    return function decorator(target, targetKey) {
      ordinaryDefineOwnMetadata(metadataKey, metadataValue, (targetKey !== undefined ? anObject : aFunction)(target), toMetaKey(targetKey));
    };
  }
});