var metadata = require('./_metadata'),
  anObject = require('./_an-object'),
  getPrototypeOf = require('./_object-gpo'),
  ordinaryHasOwnMetadata = metadata.has,
  toMetaKey = metadata.key;
var ordinaryHasMetadata = function (MetadataKey, O, P) {
  var hasOwn = ordinaryHasOwnMetadata(MetadataKey, O, P);
  if (hasOwn) return true;
  var parent = getPrototypeOf(O);
  return parent !== null ? ordinaryHasMetadata(MetadataKey, parent, P) : false;
};
metadata.exp({
  hasMetadata: function hasMetadata(metadataKey, target /*, targetKey */) {
    return ordinaryHasMetadata(metadataKey, anObject(target), arguments.length < 3 ? undefined : toMetaKey(arguments[2]));
  }
});