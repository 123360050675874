var dP = require('./_object-dp'),
  anObject = require('./_an-object'),
  getKeys = require('./_object-keys');
module.exports = require('./_descriptors') ? Object.defineProperties : function defineProperties(O, Properties) {
  anObject(O);
  var keys = getKeys(Properties),
    length = keys.length,
    i = 0,
    P;
  while (length > i) dP.f(O, P = keys[i++], Properties[P]);
  return O;
};