// 26.1.12 Reflect.preventExtensions(target)
var $export = require('./_export'),
  anObject = require('./_an-object'),
  $preventExtensions = Object.preventExtensions;
$export($export.S, 'Reflect', {
  preventExtensions: function preventExtensions(target) {
    anObject(target);
    try {
      if ($preventExtensions) $preventExtensions(target);
      return true;
    } catch (e) {
      return false;
    }
  }
});